import React from "react";

export function Button({ variant = "default", className = "", ...props }) {
  const baseStyles = "px-4 py-2 rounded focus:outline-none";
  const variantStyles = {
    default: "bg-blue-500 text-white hover:bg-blue-600",
    ghost: "bg-transparent text-black hover:bg-gray-200",
  };

  return (
    <button
      className={`${baseStyles} ${variantStyles[variant]} ${className}`}
      {...props}
    >
      {props.children}
    </button>
  );
}
