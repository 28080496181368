import React from "react";
import { Button } from "./components/ui/Button";

export default function App() {
  return (
    <div className="flex flex-col min-h-screen">
      <header className="flex items-center justify-between px-4 py-4 bg-white">
        <div className="flex items-center space-x-2">
          <img
            src="/icon.png"
            alt="AiFiltre icon"
            className="w-8 mx-auto relative z-10 rounded-md"
          />
          <span className="text-custom-red text-xl font-bold"> AiFiltre</span>
        </div>
      </header>
      <main className="flex-grow flex flex-col items-center justify-center px-4 py-12">
        <div className="text-center mb-16">
          <img
            src="/icon.png"
            alt="AiFiltre icon"
            className="w-20 mx-auto relative z-10 rounded-xl"
          />
          <h1 className="text-3xl font-bold mb-4 max-w-3xl mx-auto mt-10">
            <span className="text-custom-red"> AiFiltre:</span> Redefine Your
            Photos with AI Magic
          </h1>
          <div className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4 mt-8">
            <Button className="bg-gray-100 text-black hover:bg-gray-200 w-fit rounded-lg">
              <a
              href="https://apps.apple.com/us/app/ai-filtre/id6504581022"
              target="_blank"
              rel="noreferrer"
              className="flex items-center space-x-2"
              >
                <svg
                  className="w-6 h-6 fill-black"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                >
                  <path d="M18.71 19.5C17.88 20.74 17 21.95 15.66 21.97C14.32 22 13.89 21.18 12.37 21.18C10.84 21.18 10.37 21.95 9.09997 22C7.78997 22.05 6.79997 20.68 5.95997 19.47C4.24997 17 2.93997 12.45 4.69997 9.39C5.56997 7.87 7.12997 6.91 8.81997 6.88C10.1 6.86 11.32 7.75 12.11 7.75C12.89 7.75 14.37 6.68 15.92 6.84C16.57 6.87 18.39 7.1 19.56 8.82C19.47 8.88 17.39 10.1 17.41 12.63C17.44 15.65 20.06 16.66 20.09 16.67C20.06 16.74 19.67 18.11 18.71 19.5ZM13 3.5C13.73 2.67 14.94 2.04 15.94 2C16.07 3.17 15.6 4.35 14.9 5.19C14.21 6.04 13.07 6.7 11.95 6.61C11.8 5.46 12.36 4.26 13 3.5Z" />
                </svg>
                <span className="text-black text-xs text-left">
                  Download on the
                  <br />
                  <span className="text-lg">App Store</span>
                </span>
              </a>
            </Button>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row items-center justify-between w-full max-w-6xl mx-auto">
          <div className="lg:w-1/2 mb-8 lg:mb-0">
            <h2 className="text-2xl lg:text-4xl font-bold mb-4 text-center">
              <span className="text-custom-red">Transform Your Photos</span>{" "}
              with the Power of AI Variety.
            </h2>
          </div>
          <img
            src="/screenshot.png"
            alt="AiFiltre app interface"
            className="w-56 mx-auto relative z-10 rounded-xl"
          />
        </div>
      </main>
      <section className="py-16 text-center">
        <h2 className="text-3xl font-bold mb-4">
          Reach out if you have any questions!
        </h2>
        <Button className="bg-custom-red text-black hover:bg-custom-red-hover flex-row px-6 py-3 w-fit rounded-full text-lg font-semibold">
          <a
            href="mailto:info@alphabyte.co.jp"
            target="_blank"
            rel="noreferrer"
          >
            Ask Support →
          </a>
        </Button>
      </section>
      <footer className="bg-gray-100 py-8 px-4">
        <div className="max-w-6xl mx-auto flex flex-col md:flex-row justify-between items-center">
          <div className="mb-4 md:mb-0">© 2024 AlphaByte, Inc.</div>
          <div className="flex space-x-4">
            <a
              href="https://alphabyte.notion.site/AiFiltre-Privacy-Policy-692bdae51b0f452f93be4a4f10b66ada?pvs=4"
              target="_blank"
              rel="noreferrer"
              className="text-gray-600 hover:text-gray-900"
            >
              Privacy Policy
            </a>
            <a
              href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/"
              target="_blank"
              rel="noreferrer"
              className="text-gray-600 hover:text-gray-900"
            >
              Terms of Use
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}
